import WalletConnectProvider from '@walletconnect/ethereum-provider';

import { providers } from "ethers";
import EventEmitter from "events";
import * as utils from "./utils";
import * as constants from "./constants";
import * as sc from "./smart_contract";
import Web3 from "web3";

// trace.initSession();

const expectedChainID = +process.env.REACT_APP_EXPECTED_CHAIN_ID;
const projectID = process.env.REACT_APP_PROJECT_ID;
console.log("RUN WITH expectedChainID", expectedChainID);
const moonAmountRequired = process.env.REACT_APP_MOON_REQUIRED;
console.log("MOON REQUIRED: ", moonAmountRequired);
export const WEB3_CONNECT_MODAL_ID = "WEB3_CONNECT_MODAL_ID";
export const INJECTED_PROVIDER_ID = "injected";
export const CACHED_PROVIDER_KEY = "WEB3_CONNECT_CACHED_PROVIDER";
const origin = process.env.REACT_APP_ORIGIN_URL;


export const event = new EventEmitter();

export const walletManage = {};

const disconnectWallet = async () => {
  await walletManage.modal?.clearCachedProvider();
  if (
    walletManage.provider?.connected === true &&
    walletManage.provider?.disconnect &&
    typeof walletManage.provider?.disconnect === "function"
  ) {
    await walletManage.provider?.disconnect();
  }

  const providerInstance = walletManage.provider;
  if (providerInstance) {
    providerInstance?.removeAllListeners?.();
  }
  console.log("Account has been disconnected");
  event.emit("DISCONNECTED_WALLET");
  // const data = {
  //     event: 'DISCONNECTED',
  // };
  walletManage.account = "";
  walletManage.chainID = "";
  walletManage.meta = {};
  // delete walletManage.modal;
  delete walletManage.provider;
  // delete walletManage.web3Provider;
  // event.removeAllListeners();
  // window.postMessage(utils.buildMessage(constants.WALLET_EVENT, data), origin);
};
export const handleAccountsChanged = async (accounts) => {
  const [account] = accounts;

  console.log(`account has been changed`, account);
  if (!account) {
    await disconnectWallet();
    return;
  }
  // TODO: missing notify
  const currentAccount = walletManage.account;
  walletManage.account = account;
  const data = {
    account: account,
    chainID: walletManage.chainID,
    event: "CONNECTED",
  };
  if (
    window &&
    window.localStorage &&
    window.localStorage.getItem("walletconnect")
  ) {
    const meta = getWalletConnectMetadata();
    data.wallet = meta.wallet;
    data.meta = meta;
    walletManage.meta = meta;
  }
  if (currentAccount !== account) {
    try {
      const web3Instance = sc.initWeb3(walletManage.provider);
      await sc.initNFTABI(web3Instance);
    } catch (e) {
        await handleDisconnect();
      console.log(e);
      window.alert("Something went wrong, please refresh this page.");
      const err = new Error("disconnected");
      // @ts-ignore
      err.code = -9999;
      throw err;
    }
  }
};
const handleChainChanged = async (accounts) => {
  console.log(`Chain has been changed:`, accounts);
  const account = accounts;

  if (Web3.utils.hexToNumber(account) !== expectedChainID) {
    // handle disconnect
    await disconnectWallet();
    return;
  }
  walletManage.chainID = Web3.utils.hexToNumber(account);
  // TODO: missing notify
};
const handleDisconnect = async (error) => {
  console.log(error);
  await disconnectWallet();
};

const subscribeProvider = async (provider) => {
  if (!provider.on) {
    return;
  }

  provider.on("accountsChanged", (accounts) => {
    event.emit("CONNECTION_CHANGE", accounts);
  });
  provider.on("chainChanged", handleChainChanged);
  provider.on("disconnect", handleDisconnect);
  // Subscribe to provider connection
  provider.on("connect", (info) => {
    console.log(info);
  });
  provider.on("close", () => resetApp());
};

const connectWallet = async () => {
  try {
    // console.log(navigator.userAgent);
    // // utils.postMessageToApp(constants.APP_NOTIFICATION_EVENTS.TRANSACTION_PROCESSING, {type: constants.APP_NOTIFICATION_EVENTS.CONNECT_WALLET});
    // let web3Modal;
    // if (typeof window !== 'undefined') {
    //     web3Modal = new Web3Modal({
    //         network: 'mainnet', // optional
    //         cacheProvider: true,
    //         providerOptions, // required
    //         disableInjectedProvider: true,
    //     });

    // }

    // walletManage.modal = web3Modal;
    // emit event
    // const optionData = {
    //     event: 'CONNECTION_OPTIONS',
    //     // @ts-ignore
    //     options: getConnectOptions(web3Modal.userOptions),
    // };

    // window.postMessage(
    //     utils.buildMessage(constants.OUT_GOING_MESSAGE, optionData),
    //     origin
    // );
    const provider = await WalletConnectProvider.init({
      projectId: projectID, // REQUIRED your projectId
      chains: [expectedChainID], // REQUIRED chain ids
      showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
      rpcMap: {
          '97': 'https://rpc.walletconnect.com/v1/?chainId=eip155:97&projectId=' + projectID,
      }
    });

    await subscribeProvider(provider);
    await provider.enable();

    const web3Provider = new providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();

    const network = await web3Provider.getNetwork();
    // walletManage.modal = web3Modal;
    walletManage.provider = provider;
    // walletManage.web3Provider = web3Provider;
    if (network.chainId !== expectedChainID) {
      await handleDisconnect();
      const err = new Error("Please connect to Binance Smart Chain");
      // @ts-ignore
      err.code = -9999;
      throw err;
    }

    walletManage.account = address;
    walletManage.chainID = network.chainId;
    // provider.on('accountsChanged', (accounts) => {
    //     event.emit('CONNECTION_CHANGE', accounts);
    // });
    // provider.on('chainChanged', handleChainChanged);
    // provider.on('disconnect', handleDisconnect);
    // // Subscribe to provider connection
    // provider.on('connect', (info) => {
    //     console.log(info);
    // });
    // provider.on('close', () => resetApp());
    event.emit("CONNECTED_WALLET");
    const data = {
      account: address,
      chainID: network.chainId,
      event: "CONNECTED",
    };
    // read from storage
    if (
      window &&
      window.localStorage &&
      window.localStorage.getItem("walletconnect")
    ) {
      const meta = getWalletConnectMetadata();
      data.wallet = meta.wallet;
      data.meta = meta;
      walletManage.meta = meta;
    }
    // window.postMessage(
    //     utils.buildMessage(constants.OUT_GOING_MESSAGE, data),
    //     origin
    // );
    // TODO: init Token ABI
    // await sc.initShopNextTokenABI();
    // await sc.initWithdrawABI();
    // await sc.initSTEABI();
    try {
      const web3Instance = sc.initWeb3(provider);
      await sc.initNFTABI(web3Instance);
    } catch (e) {
      console.log(e);
      await handleDisconnect();
      const err = new Error("Something went wrong, please refresh this page.");
      // @ts-ignore
      err.code = -9999;
      throw err;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const initTokenAbi = async () => {};

const getWalletConnectMetadata = () => {
  const walletConnect = JSON.parse(
    window.localStorage.getItem("walletconnect")
  );
  const meta = {
    wallet: walletConnect.peerMeta.name,
    walletURL: walletConnect.peerMeta.url,
    key: walletConnect.key,
    handshakeId: walletConnect.handshakeId,
    handshakeTopic: walletConnect.handshakeTopic,
    clientId: walletConnect.clientId,
    peerId: walletConnect.peerId,
    chainId: walletConnect.chainId,
    accounts: walletConnect.accounts,
    clientURL: walletConnect.clientMeta.url,
    client: walletConnect.clientMeta.name,
  };
  return meta;
};

const resetApp = async () => {
  const { provider, modal } = walletManage;
  if (provider && provider.close) {
    await provider.close();
  }
  if (modal) {
    await modal.clearCachedProvider();
  }
};

const getConnectOptions = (options) => {
  let optionOut = [];
  options.forEach((element) => {
    optionOut.push({
      name: element.name,
    });
  });
  return optionOut;
};

const connectionStatus = async () => {
  return walletManage.modal?.cachedProvider;
};

export const walletManageFunctions = {
  connectWallet,
  disconnectWallet,
  connectionStatus,
};

const handleMessageFromExternal = () => {
  window.addEventListener("message", function (event) {
    if (
      event &&
      event.data &&
      typeof event.data === "string" &&
      event.data.startsWith(constants.ON_COMING_MESSAGE, 0)
    ) {
      const dataStr = event.data.replace(constants.ON_COMING_MESSAGE, "");
      const data = dataStr && JSON.parse(dataStr);
      if (data && data.event === "CONNECTION_TYPE") {
        if (!walletManage.modal || !walletManage.modal.userOptions) {
          return;
        }
        walletManage.modal.userOptions.forEach((option) => {
          if (option.name === data.type) {
            if (typeof option.onClick === "function") {
              option.onClick();
              return;
            }
            return;
          }
        });
      }
      if (
        data &&
        data.event === constants.ON_COMING_EVENTS.WALLET_CONNECT_URI
      ) {
        if (utils.isMobile.Android()) {
          const wc = {
            event: constants.OUT_GOING_EVENTS.WALLET_CONNECT_URI,
            mobile: "ANDROID",
            single: true,
            uri: data.uri,
          };
          // window.postMessage(
          //     utils.buildMessage(constants.WALLET_EVENT, wc),
          //     origin
          // );
        }
      }
      if (data && data.event) {
        onComingEventFunctionFactory(data);
      }
    }
  });
};

// handleMessageFromExternal();
const readFromConsoleLog = () => {
  // @ts-ignore
  console.defaultLog = console.log.bind(console);
  console.log = function () {
    // @ts-ignore
    console.defaultLog.apply(null, arguments);
    notifyIfLogIsWC(arguments.length && arguments[0]);
  };
};

const notifyIfLogIsWC = (log) => {
  if (log && typeof log === "string" && log.startsWith("wc:")) {
    const data = {
      event: constants.OUT_GOING_EVENTS.WALLET_CONNECT_URI,
      uri: log,
    };
    // window.postMessage(
    //     utils.buildMessage(constants.ON_COMING_MESSAGE, data),
    //     origin
    // );
  }
};
// readFromConsoleLog();
const onComingEventFunctionFactory = (data) => {};
