import React, { useContext } from "react";
import Button from "./Button";
import { WalletContext } from "./context";
import "./styles.css";

const DisconnectedView = () => {
  const {onConnectWalletClick} = useContext(WalletContext);
  return (
    <>
      <Button title="Connect wallet to claim" onClick={onConnectWalletClick} />
    </>
  );
};

export default DisconnectedView;
