import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button";
import { hideDialogModal } from "./dialog-utils";

export class DialogManager {
  static modal = undefined;
}

class DialogModal extends Component {
  dismiss = undefined;
  state = {
    isVisible: false,
    content: "",
    buttonText: "",
    image: "",
    buttonClick: () => {},
    onHide: () => {},
  };

  componentDidMount() {
    DialogManager.modal = this;
  }

  componentWillUnmount() {
    DialogManager.modal = undefined;
  }

  showModal({ content, buttonText, buttonClick, onHide, image }) {
    this.setState({
      isVisible: true,
      content: content,
      buttonText: buttonText,
      buttonClick: buttonClick,
      onHide: onHide,
      image: image,
    });
  }

  hideModal() {
    this.setState({ isVisible: false, onHide: () => {} });
  }

  render() {
    const { isVisible, content, buttonText, image, buttonClick, onHide } = this.state;
    return (
      <Modal
        dialogClassName="border-radius-20"
        onHide={onHide}
        show={isVisible}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        // onShow={onModalShow}
        centered
      >
        <div
          style={{
            backgroundColor: "#fff",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16,
            paddingBottom: 40,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", width: '100%', justifyContent: 'end'}}>
            <button className="close-image d-block mr-0 ml-auto" onClick={() => {
              hideDialogModal();
              onHide();
            }} />
          </div>
          <span
            className="text-5"
            style={{ fontWeight: 500, color: "#000", marginBottom: 40, textAlign: 'center' }}
          >{`${content}`}</span>
          {image
            ? <div className='mb-4'>
                <img
                  src={image}
                  width="241px"
                  height="auto"
                  alt=""
                />
              </div>
            : ""
          }
          <Button type="secondary" title={buttonText} onClick={buttonClick} />
        </div>
      </Modal>
    );
  }
}
export default DialogModal;

// export default class extends Component {
//   dismiss = undefined;
//   state = {
//     isVisible: false,
//     title: '',
//     styleTitle: null,
//     content: '',
//     styleContent: null,
//     buttons: null,
//     childView: null,
//     icon: null,
//   };
//   componentDidMount() {
//     DialogManager.modal = this;
//   }
//   componentWillUnmount() {
//     DialogManager.modal = undefined;
//   }
//   toggleDialogModal = ({
//     isVisible = false,
//     icon,
//     title = '',
//     styleTitle = null,
//     content = '',
//     styleContent = null,
//     buttons = null,
//     childView = null,
//   }) => {
//     this.setState({
//       isVisible,
//       content,
//       title,
//       styleTitle,
//       styleContent,
//       buttons,
//       childView,
//       icon,
//     });
//     if (!isVisible) {
//       this.dismiss = undefined;
//     }
//   };
//   setDismiss = dismiss => {
//     if (dismiss && typeof dismiss === 'function') {
//       this.dismiss = dismiss;
//     }
//   };
//   updateContent = (
//     title = '',
//     styleTitle = null,
//     content = '',
//     styleContent = null,
//     buttons = null,
//     childView = null,
//     icon = null,
//   ) => {
//     const {isVisible} = this.state;
//     if (!isVisible) {
//       return;
//     }
//     this.setState({
//       content,
//       title,
//       styleTitle,
//       styleContent,
//       buttons,
//       childView,
//       icon,
//     });
//   };
//   render() {
//     const {
//       isVisible,
//       title = '',
//       styleTitle = null,
//       content = '',
//       styleContent = null,
//       buttons = null,
//       childView = null,
//       icon = null,
//     } = this.state;

//     return (
//       <Modal>
//         <span>adasdasdasd</span>
//       </Modal>
//     );
//   }
// }
