import { get } from "lodash";
import React, { createRef, useContext } from "react";
import ConnectedView from "../connected";
import { WalletContext } from "../context";
import DisconnectedView from "../disconnect";
import "./style.css";

const SwapContent = () => {
  const {wallet} = useContext(WalletContext);
  return <div className="Content">
    {get(wallet, 'connected') === true ? <ConnectedView /> : <DisconnectedView />}
  </div>;
};

export default SwapContent;