import {DialogManager} from './DialogModal';

export const showDialogModal = ({
  content,
  buttonText = '',
  image = '',
  buttonClick = () => {},
  onHide = () => {}
}) => {
  try {
    if (!DialogManager.modal) {
      return;
      // throw 'Loading modal is existed';
    }
    DialogManager.modal.showModal({
      content,
      buttonText,
      image,
      buttonClick,
      onHide
    });
  } catch (error) {
    console.error('showLoadingModal', error);
  }
};

export const hideDialogModal = () => {
  try {
    if (!DialogManager.modal) {
      return;
      // throw 'Loading modal is existed';
    }
    DialogManager.modal.hideModal();
  } catch (error) {
    console.error('hideLoadingModal', error);
  }
};

export const updateContentModal = ({
  title = '',
  styleTitle = null,
  content = '',
  styleContent = null,
  buttons = null,
  childView = null,
  icon = null,
}) => {
  try {
    if (!DialogManager.modal) {
      return;
    }
    DialogManager.modal.updateContent({
      title,
      styleTitle,
      content,
      styleContent,
      buttons,
      childView,
      icon,
    });
  } catch (error) {
    console.error('updateContentModal', error);
  }
};

export const setDismissModal = (dismiss = () => {}) => {
  try {
    if (!DialogManager.modal) {
      return;
    }
    DialogManager.modal.setDismiss(dismiss);
  } catch (error) {
    console.error('setDismiss', error);
  }
};
