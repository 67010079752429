import React from 'react'
import "./styles.css";

const buttonStyle = {
  primary: {
    backgroundColor: '#fff',
    // paddingRight: 48,
    // paddingLeft: 48,
    paddingTop: 12,
    paddingBottom: 12,
    border: 'none',
    borderRadius: 40,
  },
  secondary: {
    backgroundColor: '#5865EF',
    // paddingRight: 48,
    // paddingLeft: 48,
    paddingTop: 12,
    paddingBottom: 12,
    border: '1px solid white',
    borderRadius: 40,
  },
}


const buttonTextStyle = {
  primary: {
    color: '#5865EF',
    // fontSize: 15.6,
    fontWeight: '700',
    whiteSpace: 'nowrap'
  },
  secondary: {
    color: '#fff',
    // fontSize: 15.6,
    fontWeight: '700',
    whiteSpace: 'nowrap'
  },
}


const Button  = ({onClick = () => {}, title = '', type = 'primary'}) => {
  return <button className='ButtonContainer' style={buttonStyle[type]} onClick={onClick}>
    <span className='text-4' style={buttonTextStyle[type]}>{title}</span>
  </button>
};

export default Button;