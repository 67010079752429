import React, {
  useContext,
  useRef,
  useState,
} from "react";
import Axios from 'axios';
import * as sc from "../smart_contract";
import { get, random } from "lodash";
import { Modal} from "react-bootstrap";
import { walletManageFunctions, walletManage } from "../web3";
import "../App.css";
import "./styles.css";
import { WalletContext } from "./context";
import Web3 from "web3";
import Button from "./Button";
import { Colors, currencyFormat } from "../utils";
import { hideDialogModal, showDialogModal } from "./Dialog/dialog-utils";
import { randomBytes } from 'ethers/lib/utils';

const ConnectedView = () => {
  const {
    wallet,
    showAppLoading,
    hideAppLoading,
  } = useContext(WalletContext);
  const NftServer = Axios.create({
    baseURL: process.env.REACT_APP_NFT_SERVER,
  });
  const [tokenID, setTokenID] = useState(0);
  const [signature, setSignature] = useState('');
  const [transactionHash, setTransactionHash] = useState('');

  const onSubmitClaimClick = async (tokenId, signature, transactionHash) => {
    if (tokenId && signature) {
      try {
        showAppLoading({
          message: "Please open your wallet and confirm transaction",
          showCloseButton: false,
        });
        if (!transactionHash) {
          const res = await sc.claimNFT(tokenId, signature);
          transactionHash = res.transactionHash;
        }
        hideAppLoading();
        showDialogModal({
          content: `NFT card case #${tokenId} was successfully claimed!`,
          buttonText: "Transaction detail",
          image: "https://stc.shopnext.io/nft-card/ad/card-case.png",
          buttonClick: () => {
            window.open(
              `${process.env.REACT_APP_BSC_DOMAIN}/${transactionHash}`
            );
            hideDialogModal();
          },
        });
      } catch (e) {
        hideAppLoading();
      }
    } else {
      showDialogModal({
        content: "Your wallet address is not eligible!",
        buttonText: "Support Center",
        buttonClick: () => {
          window.open(
            `https://linktr.ee/helloshopnext`
          );
          hideDialogModal();
        },
      });
    }
  };

  const getTokenAndSignature = async () => {
    try {
      showAppLoading({
        message: "Please open your wallet and sign message",
        showCloseButton: false,
      });
      const message = randomBytes(5).toString().split(',').join('');
      const sig = await sc.signMessage(message.toString());
      const body = {
        signature: sig,
        wallet: sc.getCurrentAddress(),
        message,
        type: 1
      };
      let tokenID, signature, transactionHash;
      try {
        const resp = await NftServer.get('/nft/web/reward', { params: body });
        if (resp.data && !resp.data.code) {
          tokenID = resp.data.token_id;
          signature = resp.data.signature;
          transactionHash = resp.data.transaction_hash;
        } else {
          throw new Error(resp.data?.message || 'Something went wrong, please try again!');
        }
      } catch (err) {
        console.error(err);
      }
      hideAppLoading();
      return { tokenID, signature, transactionHash };
    }
    catch (e) {
      hideAppLoading();
      throw e;
    }
  }

  const onClaimClick = async () => {
    try {
      let cloneTokenId = tokenID, cloneSignature = signature, cloneTransactionHash = transactionHash;
      if (!cloneTokenId || !cloneSignature) {
        const result = await getTokenAndSignature();
        setTokenID(result.tokenID);
        setSignature(result.signature);
        setTransactionHash(result.transactionHash);
        cloneTokenId = result.tokenID;
        cloneSignature = result.signature;
        cloneTransactionHash = result.transactionHash;
      }
      await onSubmitClaimClick(cloneTokenId, cloneSignature, cloneTransactionHash);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <span
          className="text-4"
          style={{ fontWeight: "500", color: "#1ADEE2", lineHeight: 2.0 }}
        >
          Your wallet address
        </span>
      </div>
      <span
        className="text-4"
        style={{
          color: "#fff",
          wordWrap: "break-word",
          fontWeight: "normal",
        }}
      >
        {get(wallet, "wallet.account")}
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 40,
        }}
      >
        <Button
          title="Claim now"
          onClick={onClaimClick}
        />
        <div style={{ width: 20 }} />
        <Button
          title="Disconnect"
          type="secondary"
          onClick={walletManageFunctions.disconnectWallet}
        />
      </div>
    </>
  );
};

export default ConnectedView;
