import React, { useEffect, useState } from "react";
import {
  walletManageFunctions,
  event,
  walletManage,
  handleAccountsChanged,
} from "../web3";
import * as sc from "../smart_contract";

// @ts-ignore
export const WalletContext = React.createContext();

export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState(null);
  const [loading, setLoading] = useState({
    show: false,
    message: '',
    buttonText: null,
    buttonClick: () => {},
    showSpinner: true,
    showCloseButton: true,
  });
  const [lastEvent, setLastEvent] = useState(null);
  useEffect(() => {
    event.on("CONNECTED_WALLET", handleConnectEvent);
    event.on("DISCONNECTED_WALLET", handleDisconnectWalletEvent);
    event.on("LAST_EVENT", handleLastEvent);
    event.on("CONNECTION_CHANGE", handleConnectionChanged);
    return () => {
      event.off("CONNECTED_WALLET", handleConnectEvent);
      event.off("DISCONNECTED_WALLET", handleDisconnectWalletEvent);
      event.off("LAST_EVENT", handleLastEvent);
      event.off("CONNECTION_CHANGE", handleConnectionChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAppLoading = ({message, buttonText = null, showSpinner = true, showCloseButton = true, buttonClick = () => {}}) => {
    setLoading({
      show: true,
      message: message,
      buttonText: buttonText,
      buttonClick: buttonClick,
      showSpinner: showSpinner,
      showCloseButton: showCloseButton
    });
  };

  const hideAppLoading = () => {
    setLoading({show: false, showSpinner: false, message: '', buttonText: null, showCloseButton: true, buttonClick: () => {}});
  }

  const handleConnectEvent = () => {
    setWallet((preState) => ({ ...preState, connected: true, wallet: walletManage }));
  };

  const handleDisconnectWalletEvent = () => {
    setWallet({
      connected: false,
    });
  };

  const handleLastEvent = async (data) => {
    setLastEvent(data);
  };

  const handleConnectionChanged = async (accounts) => {
    try {
      await handleAccountsChanged(accounts);
      console.log('handleConnectionChanged done');
      // await getWalletAllowance();
      // await getClaimAble();
      // await getOldNextBalance();
    } catch (e) {}
  };

  const onConnectWalletClick = async () => {
    try {
      await walletManageFunctions.connectWallet();
      // await getWalletAllowance();
      // await getClaimAble();
      // await getOldNextBalance();
    } catch (e) {
      if (e.code === -9999) {
        window.alert(e.message);
      }
      // window.alert(e);
    }
  };

  useEffect(() => {
    console.log(wallet);
  }, [wallet]);

  return (
    <WalletContext.Provider value={{ wallet, setWallet, lastEvent, onConnectWalletClick, loading, showAppLoading, hideAppLoading}}>
      {children}
    </WalletContext.Provider>
  );
};
