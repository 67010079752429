import * as utils from './utils';
export const ERROR_CODE = {
    // SAVING_CLOSED_OR_INVALID: -1000,
    // SAVING_ALREADY_EXISTED: -1001,
    // SAVING_INSUFFICIENT_AMOUNT: -1002,
    // SAVING_AUTO_RESAVING_ALREADY_SET: -1003,
    EMPTY_WALLET_MANAGE_PROVIDER: -1000,
};

const ERROR_MAPPING = {
    // [ERROR_CODE.SAVING_CLOSED_OR_INVALID]: 'saving_closed_or_invalid',
    // [ERROR_CODE.SAVING_ALREADY_EXISTED]: 'saving_already_existed',
    // [ERROR_CODE.SAVING_INSUFFICIENT_AMOUNT]: 'saving_insufficient_amount',
    // [ERROR_CODE.SAVING_AUTO_RESAVING_ALREADY_SET]: 'saving_auto_resaving_already_set',
    [ERROR_CODE.EMPTY_WALLET_MANAGE_PROVIDER]: 'empty_wallet_manage_provide',
};


export const newInternalError = (code, message) => {
    const err = new Error();
    // @ts-ignore
    err.code = code;
    // @ts-ignore
    err.internal = true;
    const defaultMessage = utils.getLocaleMessage();
    err.message = message || defaultMessage;
    // const error 
    if (ERROR_MAPPING[code]) {
        err.message = utils.getLocaleMessage(ERROR_MAPPING[code] || defaultMessage);
    }
    return err;
};