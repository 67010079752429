import BigNumber from "bignumber.js";
import Web3 from "web3";
// @ts-ignore
import NFTAbi from "./abi/NFTCard.abi.json";
import * as web3Internal from "./web3";
import * as errors from "./error";
// @ts-ignore
import * as utils from "./utils";
// @ts-ignore
import * as constants from "./constants";
import { BN } from "bn.js";
// load abi

const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
console.log("CONNECT TO TOKEN ADDRESS: ", tokenAddress);
let NFTABI;
let web3 = new Web3();
// @ts-ignore
// let WithdrawABI;
// @ts-ignore
// let STEABI;
let SWAPShopNEXTABI;
export const initWeb3 = (provider) => {
  console.log('initWeb3 from provider', provider);
  web3 = new Web3(provider);
  return web3;
}


export const initNFTABI = async (web3Instance) => {
  // delete web3Internal.walletManage.provider.__proto__.request;
  // web3Internal.walletManage.provider.hasOwnProperty("request") &&
  //   delete web3Internal.walletManage.provider.request;
  // if (!web3) web3 = new Web3(web3Internal.walletManage.provider);
  NFTABI = new web3Instance.eth.Contract(NFTAbi, tokenAddress);
  return true;
};

// @ts-ignore
const checkConnectStatus = async () => {
  if (!NFTAbi || !web3Internal.walletManage.account) {
    const err = new Error("disconnected");
    // @ts-ignore
    err.code = -9999;
    throw err;
  }
};


const checkBNBBalance = async () => {
  // check balance
  const balanceBNB = await web3.eth.getBalance(
    web3Internal.walletManage.account
  );
  // if (web3.toDecimal(balanceBNB) === 0) {
  if (new BigNumber(balanceBNB).eq(new BigNumber("0"))) {
    const err = new Error("Your BNB balance is not enough");
    console.log(err.message);
    // @ts-ignore
    err.code = "not_have_bnb";
    throw err;
  }
};


export const getCurrentAddress = () => web3Internal.walletManage.account;

export const encryptMsg = msg => `${msg}`;

export const signMessage = async function(message) {
  const account = web3Internal.walletManage.account;
  const result = await web3.eth.personal.sign(message, account, '');

  return result;
}

export const claimNFT = async function(tokenID, signature) {
  await checkConnectStatus();
  await checkBNBBalance();
  return new Promise((resolve, reject) => {
    NFTABI.methods
    // @ts-ignore
    .claimNft(web3Internal.walletManage.account, Web3.utils.toBN(new BigNumber(`${tokenID}`)), signature)
    .send({
        from: web3Internal.walletManage.account
    })
    .on('transactionHash', function (hash) {
        console.log('hash', hash);
    })
    .on('receipt', (receipt) => {
        console.log('receipt', receipt);
        // return resolve(receipt);
    })
    .on('confirmation', function (confirmationNumber, receipt) {
        // console.log('confirmationNumber, receipt', confirmationNumber, receipt)
    })
    .on('error', (err) => {
        console.log('err', err);
        return reject(err);
    })
    .then((receipt) => {
        console.log('final', receipt);
        return resolve(receipt);
    })
    .catch((err) => {
        return reject(err);
    });
  });
}